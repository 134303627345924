// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_features = "Features-module__container_features___3jpUr";
export var container_features__content_service = "Features-module__container_features__content_service___ki7+u";
export var container_features__content_svg = "Features-module__container_features__content_svg___ZRpJ7";
export var container_features__image = "Features-module__container_features__image___EC9+2";
export var container_features__text = "Features-module__container_features__text___AeTrk";
export var container_features__title = "Features-module__container_features__title___SfS3Q";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";