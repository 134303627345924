import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './Features.module.scss';

const features = [
  {
    id: 0,
    title: 'youTellUsWhatYouNeed',
    text: 'talentSelectionProcessQuick',
    icon: (
      <StaticImage
        src="../../../../../../images/optimized.webp"
        alt="optimized"
        placeholder="blurred"
        loading="lazy"
        layout="constrained"
        formats={["auto", "webp", "avif"]}
        className={styles.container_features__image}
      />
    ),
  },
  {
    id: 1,
    title: 'codeLovers',
    text: 'yourTimeIsMoney',
    icon: (
      <StaticImage
        src="../../../../../../images/code_lovers.webp"
        alt="code_lovers"
        placeholder="blurred"
        loading="lazy"
        layout="constrained"
        formats={["auto", "webp", "avif"]}
        className={styles.container_features__image}
      />
    ),
  },
  {
    id: 2,
    title: 'weSimplifyProcess',
    text: 'simplifyingTheTalentAcquisitionProcess',
    icon: (
      <StaticImage
        src="../../../../../../images/simplify.webp"
        alt="simplify"
        placeholder="blurred"
        loading="lazy"
        layout="constrained"
        formats={["auto", "webp", "avif"]}
        className={styles.container_features__image}
      />
    ),
  },
  {
    id: 3,
    title: 'weProvideAIProcess',
    text: 'providingAnAIProcess',
    icon: (
      <StaticImage
        src="../../../../../../images/ia_process.webp"
        alt="ia_process"
        placeholder="blurred"
        loading="lazy"
        layout="constrained"
        formats={["auto", "webp", "avif"]}
        className={styles.container_features__image}
      />
    ),
  },
];

const Features = ({ intl: { formatMessage } }) => {
  return (
    <section className={styles.container_features}>
      {features.map((item) => (
        <article
          key={item.id}
          className={styles.container_features__content_service}
        >
          <div className={styles.container_features__content_svg}>
            {item.icon}
          </div>
          <div>
            <h4 className={styles.container_features__title}>
              {formatMessage({ id: item.title })}
            </h4>
            <p className={styles.container_features__text}>
              {formatMessage({ id: item.text })}
            </p>
          </div>
        </article>
      ))}
    </section>
  );
};

export default injectIntl(Features);

Features.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
